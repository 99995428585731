// 黑名单
<template>
  <div class="menu">
    <div class="screening">
      <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true"
                 width="600px">
        <el-form label-position="left" label-width="100px" size="small" :model="searchFrom" ref="upform" >
          <el-form-item label="主叫号码" v-if="telType!=2">
            <el-input v-model="searchFrom.telA" class="input-searchFrom-width" placeholder="请输入需要查询的主叫号码"
                      size="mini">
            </el-input>
          </el-form-item>
          <el-form-item label="被叫号码" v-if="telType!=2">
            <el-input v-model="searchFrom.number" class="input-searchFrom-width" placeholder="请输入需要查询的被叫号码"
                      size="mini">
            </el-input>
          </el-form-item>
            <el-form-item label="小号号码" v-if="telType==2">
            <el-input v-model="searchFrom.number" class="input-searchFrom-width" placeholder="请输入需要查询的小号号码"
                      size="mini">
            </el-input>
          </el-form-item>
          <el-form-item label="禁用时间">
            <el-date-picker v-model="valueTime" type="datetimerange" start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            clearable value-format="YYYY-MM-DD HH:mm:ss" size="mini" :default-time="defaultTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="禁用原因" prop="amountId" v-if="telType!=2">
            <el-select class="input-searchFrom-width" v-model="searchFrom.disableReason" placeholder="请选择"
                       style="width: 100%" clearable>
              <el-option v-for="(item, index) in [{
                              id:1,
                              name:'违规禁用'
                          },{
                              id:0,
                              name:'手动录入'
                          }]" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="reset" round>
                重置
              </el-button>
              <el-button class="confirmAdd" @click="onSubmit" round>
                查询
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <el-row>
      <el-col :span="12">
        <div class="flex-wrap-flex-start">
          <div class="title-name">
            数据列表
          </div>
          <div class="ml-20">
            <el-radio-group v-model="telType" size="small" @change="telTypeChange">
              <el-radio-button label="0">主叫</el-radio-button>
              <el-radio-button label="1">被叫</el-radio-button>
              <el-radio-button label="2">小号</el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询
            </el-button>
          </div>
          <div class="ml-10" v-if="telType!=2">
            <el-button class="button-el" size="medium" @click="centerDialogVisible = true">
              <i class="iconfont icon-tianjia1"/>
              新增
            </el-button>
          </div>
          <div class="ml-10" v-if="telType!=2">
            <el-button class="button-el" size="medium" @click="DialogBatchaddVisible = true">
              <i class="iconfont icon-tianjia1"/>
              批量新增
            </el-button>
          </div>
            <div class="ml-10" v-if="userType == 'system'">
            <el-button class="button-el" size="medium" @click="delBlackList()">
              <i class="el-icon-minus"></i>
              批量解除
            </el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="reset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
                    style="width: 100%" size="mini" :row-style="{height:'50px'}" :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" default-expand-all
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    @selection-change="handleSelectionChange">
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
            <!-- <el-table-column
                        label="用户id" v-if="userType == 'system'">
                            <template #default="scope">{{ scope.row.uid == null|| scope.row.uid == ''?'-': scope.row.uid}}</template>
                        </el-table-column> -->
            <el-table-column v-if="telType == 0" label="禁用主叫">
              <template
                  #default="scope">{{ scope.row.telA == null || scope.row.telA == '' ? '-' : scope.row.telA }}
              </template>
            </el-table-column>
               <el-table-column v-if="telType == 2" label="禁用小号">
              <template
                  #default="scope">{{ scope.row.number == null || scope.row.number == '' ? '-' : scope.row.number }}
              </template>
            </el-table-column>

            <el-table-column v-if="telType == 1" label="禁用被叫">
              <template
                  #default="scope">{{ scope.row.number == null || scope.row.number == '' ? '-' : scope.row.number }}
              </template>
            </el-table-column>

            <el-table-column v-if="telType == 1" label="所属用户">
              <template
                  #default="scope">
                {{ scope.row.nickname == null || scope.row.nickname == '' ? '-' : scope.row.nickname }}
              </template>
            </el-table-column>
            <el-table-column label="禁用来源" v-if="telType!=2">
              <template
                  #default="scope">
                {{
                  scope.row.disableReasonText == null || scope.row.disableReasonText == '' ? '-' : scope.row.disableReasonText
                }}
              </template>
            </el-table-column>
            <el-table-column label="禁用原因" v-if="telType != 2">
              <template
                  #default="scope">
                {{
                  scope.row.hitType == null || scope.row.hitType == '' ? '-' : scope.row.hitType
                }}
              </template>
            </el-table-column>
                <el-table-column label="禁用原因" v-if="telType == 2">
              <template
                  #default="scope">
                {{
                  scope.row.disableReason == null || scope.row.disableReason == '' ? '-' : scope.row.disableReason
                }}
              </template>
            </el-table-column>
            <el-table-column v-if="telType == 0||telType == 2" label="备注">
              <template
                  #default="scope">{{ scope.row.remark == null || scope.row.remark == '' ? '-' : scope.row.remark }}
              </template>
            </el-table-column>
            <el-table-column v-if="telType == 1" label="备注">
              <template
                  #default="scope">{{ scope.row.remark == null || scope.row.remark == '' ? '-' : scope.row.remark }}
              </template>
            </el-table-column>
            <el-table-column label="禁用时间">
              <template
                  #default="scope">
                {{ scope.row.createTime == null || scope.row.createTime == '' ? '-' : scope.row.createTime }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template #default="scope">
                <div class="flex-nowrap-space-around" v-if="telType!=2">
                  <span class="button-font-color" @click="viewDetails(scope.row)" >
                    <icon-font type="icon-caidanguanli"/>
                    详情
                  </span>
                  <span class="button-font-color" @click="confirmEvent(scope.row)">
                    <icon-font type="icon-jiejinxianshou"/>
                    解除禁用
                  </span>
                </div>
                   <span class="button-font-color" @click="confirmEvent(scope.row)"  v-if="telType==2">
                    <icon-font type="icon-jiejinxianshou"/>
                    解除禁用
                  </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                       background :page-sizes="[10, 20, 30, 40]" :page-size="searchFrom.pageSize"
                       layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="禁用详细" v-model="detailsDialog" width="860px" :close-on-click-modal="false"
               :destroy-on-close="true"
               @close='closeDialog'>
      <div class="center-body">
        <el-descriptions class="margin-top" :column="3" border>
          <el-descriptions-item v-if="telType == '0'">
            <template #label>
              <div class="cell-item">
                主叫号码：
              </div>
            </template>
            {{ disableDetails.telA }}
          </el-descriptions-item>
          <el-descriptions-item v-if="telType == '1'">
            <template #label>
              <div class="cell-item">
                被叫号码：
              </div>
            </template>
            {{ disableDetails.telB }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                禁用时间：
              </div>
            </template>
            {{ disableDetails.disableTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                命中类型：
              </div>
            </template>
            {{ disableDetails.hitType }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                命中关键词：
              </div>
            </template>
            {{ disableDetails.hitTypeDesc }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <template #footer>
        <div>
          <el-button class="cancel" plain round @click="detailsDialog = false">知道了</el-button>
        </div>
      </template>
    </el-dialog>
    <div class="popup-view">
      <!-- 添加黑名单模态框 -->
      <el-dialog title="添加黑名单 (重复的黑名单号码会自动过滤)" v-model="centerDialogVisible" width="65%"
                 :style="{minHeight:'90%'}"
                 :close-on-click-modal="false"
                 :destroy-on-close="true" @close='closeDialog'>
        <div class="center-body addBlack">
          <div class="header">
            <el-radio-group v-model="telTypeRadio">
              <el-radio label="0">主叫</el-radio>
              <el-radio label="1">被叫</el-radio>
            </el-radio-group>
            <el-button type="success" size="small" plain @click="addprivacy">新增</el-button>
          </div>
          <div class="privacy-most">
            <div class="m3-flex m3-align-items-end m3-margin-bottom-m" v-for="(item,index) in blackTelADtoLists"
                 :key="index">
              <div class="m3-flex-1 m3-flex">
                <div v-if="telTypeRadio==0" class="m3-flex-1 m3-flex-col m3-padding-right-l">服务商：
                  <el-select v-model="item.amountId" placeholder="请选择服务商" filterable clearable
                             :options="amountOption"
                             ref="select">
                    <el-option :value="item.value" v-for="item in amountOption"
                               :label="item.label"
                               :key="item.value">{{
                        item.label
                      }}
                    </el-option>
                  </el-select>
                </div>
                <div v-if="telTypeRadio==0" class="m3-flex-1 m3-flex-col m3-padding-right-l">禁用小时：
                  <el-select v-model="item.disableDuration" placeholder="请选择禁用小时" filterable clearable
                             :options="disableDurationList"
                             ref="select">
                    <el-option :value="item.value" v-for="item in disableDurationList"
                               :label="item.label"
                               :key="item.value">{{
                        item.label
                      }}
                    </el-option>
                  </el-select>
                </div>
                <div v-if="telTypeRadio==0" class="m3-flex-1 m3-flex-col m3-padding-right-l">原因：
                  <el-select v-model="item.reason" placeholder="请选择原因" clearable
                             :options="bannedReasonList"
                             ref="select">
                    <el-option :value="item.label" v-for="item in bannedReasonList"
                               :label="item.label"
                               :key="item.label">{{
                        item.label
                      }}
                    </el-option>
                  </el-select>
                </div>
                <div class="m3-flex-1 m3-flex-col m3-padding-right-l">
                  手机号:
                  <el-input class="m3-width-full" v-model="item.telA" placeholder="请输入手机号码"></el-input>
                  <!-- <el-tooltip content="重复的黑名单号码会自动过滤" placement="top" effect="light">
                    <i class="iconfont img-icon icon-bangzhu" />
                  </el-tooltip> -->
                </div>
                <div class="m3-flex-1 m3-flex-col m3-padding-right-l">
                  备注:
                  <el-input class="m3-width-full" v-model="item.remark" placeholder="请输入备注"></el-input>
                </div>
              </div>

              <div>
                <el-button plain type="danger" @click="deleteOne(index,item)">
                  删除
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <div>
            <el-button plain @click="resetForm()">取消</el-button>
            <el-button type="primary" @click="submitForm('dynamicValidateForm')">添加</el-button>
          </div>
        </template>
      </el-dialog>
      <!-- 批量添加黑名单模态框 -->
      <el-dialog title="批量添加黑名单 (重复的黑名单号码会自动过滤)" v-model="DialogBatchaddVisible" width="35%"
                 :close-on-click-modal="false" :destroy-on-close="true" @close='closeDialog'>
        <div class="center-body addBlack">
          <div class="header">
            <el-radio-group v-model="batchTelTypeRadio">
              <el-radio label="0">主叫</el-radio>
              <el-radio label="1">被叫</el-radio>
            </el-radio-group>
          </div>
          <div v-if="batchTelTypeRadio==0"
               class="m3-flex-col m3-margin-top-m">服务商：
            <el-select v-model="batchForm.amountId" placeholder="请选择服务商" filterable clearable
                       :options="amountOption"
                       ref="select">
              <el-option :value="item.value" v-for="item in amountOption"
                         :label="item.label"
                         :key="item.value">{{
                  item.label
                }}
              </el-option>
            </el-select>
          </div>
          <div v-if="batchTelTypeRadio==0"
               class="m3-flex-col m3-margin-top-m">禁用小时：
            <el-select v-model="batchForm.disableDuration" placeholder="请选择禁用小时" filterable clearable
                       :options="disableDurationList"
                       ref="select">
              <el-option :value="item.value" v-for="item in disableDurationList"
                         :label="item.label"
                         :key="item.value">{{
                  item.label
                }}
              </el-option>
            </el-select>
          </div>
          <div v-if="batchTelTypeRadio==0"
               class="m3-flex-col m3-margin-top-m">原因：
            <el-select v-model="batchForm.reason" placeholder="请选择原因" clearable :style="{width:'100%'}"
                       :options="bannedReasonList"
                       ref="select">
              <el-option :value="item.label" v-for="item in bannedReasonList"
                         :label="item.label"
                         :key="item.label">{{
                  item.label
                }}
              </el-option>
            </el-select>
          </div>
          <div class="m3-flex-col m3-margin-top-m">
            手机号:
            <el-input v-model="batchForm.phoneString" type="textarea" :autosize="{ minRows: 6}"
                      placeholder="请输入手机号码(输入多个号码时请换行输入)">
            </el-input>
          </div>
          <div class="m3-flex-col m3-margin-top-m">
            备注:
            <el-input v-model="batchForm.remark" type="text" clearable class="m3-width-full"
                      placeholder="请输入备注"></el-input>
          </div>
        </div>
        <template #footer>
          <div>
            <el-button plain @click="handleBatchCancle">取消</el-button>
            <el-button type="primary" @click="handleBatchAdd">添加</el-button>
          </div>
        </template>
      </el-dialog>

      <!-- 批量删除黑名单模态框 -->
      <el-dialog title="批量解除黑名单 (重复的黑名单号码会自动过滤)" v-model="DialogBatchaddVisible1" width="35%"
                 :close-on-click-modal="false" :destroy-on-close="true" @close='closeDialog'>
        <div class="center-body addBlack">
         
          <div v-if="telType==0"
               class="m3-flex-col m3-margin-top-m">服务商：
            <el-select v-model="delForm.amountId" placeholder="请选择服务商" filterable 
                       :options="amountOption"
                       ref="select">
              <el-option :value="item.value" v-for="item in amountOption"
                         :label="item.label"
                         :key="item.value">{{
                  item.label
                }}
              </el-option>
            </el-select>
          </div>
        
       
          <div class="m3-flex-col m3-margin-top-m">
            手机号:
            <el-input v-model="delForm.phoneString" type="textarea" :autosize="{ minRows: 6}"
                      placeholder="请输入手机号码(输入多个号码时请换行输入)">
            </el-input>
          </div>
         
        </div>
        <template #footer>
          <div>
            <el-button plain @click="handleBatchCancle">取消</el-button>
            <el-button type="primary" @click="handleBatchDel">解除</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import blackNumber from '@/api/open/black/blackNumber'
import voiceType from '@/api/open/voice/voiceType'
import {IconFont} from '@/utils/iconfont'
import _lineService from "@/api/open/LineQualityInspections";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {modalConfirm} from "@/utils/common/modalConfirm";

export default {
  components: {
    IconFont,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      let num = 0,
          types = null,
          errorItem
      if (value == null) {
        callback(new Error('请填写号码 (多个号码请换行输入)'))
      } else if (value.length == 0) {
        callback(new Error('请填写号码 (多个号码请换行输入)'))
      } else {
        let newArr = []
        newArr = value.split(/[(\r\n)\r\n]+/)
        newArr.forEach((item, i) => {
          if (
              item.match(
                  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9])\d{8}$/
              ) == null
          ) {
            // if(item.match(/^(1[3|4|5|6|7|8|9])\\d{9}$|^0\\d{2,3}-?\\d{7,8}$|^9\\d{9}/) == null){
            // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
            num++
            types = i
            errorItem = item
          }
        })
        if (num != 0) {
          callback(
              new Error(
                  '请输入正确的号码 (请检查第' +
                  (types + 1) +
                  '行：' +
                  errorItem +
                  ')'
              )
          )
        } else {
          callback()
        }
      }
    }
    const checkPhone = (rule, value, callback) => {
      var reg = /^1[3456789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号码格式'))
      } else if (value == null || value == '') {
        callback(new Error('手机号码不能为空'))
      } else {
        callback()
      }
    }
    return {
      //批量添加黑名单
      batchForm: {
        phoneString: '',
        phone: [],
        remark: null,
        amountId: '0',
        reason: null,
      },
       //批量删除黑名单
      delForm: {
        phone: [],
        amountId: '0',
        phoneString: '',
      },
      showTips: false,
      tableHeight: '',
      searchFrom: {
        page: 1,
        pageSize: 10,
        telA: '',
        number: '',
      },
      telType: '0',
      telTypeRadio: '0',
      batchTelTypeRadio: '0', //批量
      detailsDialog: false,
      disableDetails: {},
      theQueryDialog: false,
      total: 0,
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      valueTime: [], //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 8, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ], //默认时间 '12:00:00', '08:00:00'
      activeNames: [], //折叠面板
      tableData: [], //菜单表
      // loding 状态启用
      loading: true,
      userType: this.$store.state.userType,
      upform: {
        number: null,
      },
      amountOption: [],
      bannedReasonList: [
        {label: '因拨打话务与报备话术不一致，但不涉及禁入场景，主叫被临时禁用，请联系平台客服处理。', value: 1,},
        {label: '因涉及辱骂客户或与客户对骂等场景，主叫被临时禁用，请联系平台客服处理。', value: 2,},
        {label: '因拨打话务涉及平台禁入场景，主叫已被禁用，请联系平台客服处理。', value: 3,},
        {label: '因拨打话务涉及该线路禁止提及的关键词，主叫已被禁用，请联系平台客服处理。', value: 4,},
      ],
      disableDurationList: [
        {label: '4小时', value: 4,},
        {label: '8小时', value: 8,},
        {label: '12小时', value: 12,},
        {label: '24小时', value: 24,},
        {label: '48小时', value: 48,},
        {label: '永久', value: '',},
      ],
      centerDialogVisible: false, //新增模态框
      DialogBatchaddVisible: false, //批量新增模态框
      DialogBatchaddVisible1: false, //批量删除模态框
      textarea1: '',
      blackTelADtoLists: [
        {
          telA: '',
          disableDuration: null,//null表示没选，’‘空字符串表示永久
          remark: '',
          amountId: '0',
          reason: '',
          hitType: '打人',
        },
      ],
    }
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
  },
  watch: {},
  mounted() {
    this.getDataList(true);
    this.getAmountList();
  },
  methods: {
    delBlackList(){
     this.DialogBatchaddVisible1 = true
    },
    async getAmountList() {
      const res = await _lineService._recordService.getAmountList()
      this.amountOption = res?.data?.map(item => {
        return {label: item.name, value: item.id}
      }) || [];
      let all = {label: '全部', value: '0'};
      this.amountOption.splice(0, 0, all);
    },
    //批量添加黑名单取消
    handleBatchCancle() {
      // this.batchForm.amountId = null;
      // this.batchForm.reason = null
      // this.batchForm.phoneString = '';
      // this.batchForm.phone = [];
      // this.batchForm.remark = ''
      this.DialogBatchaddVisible = false
      this.DialogBatchaddVisible1 = false
    },
    //批量添加黑名单添加
    async handleBatchAdd() {
      let arr = this.batchForm.phoneString.split(/\r\n|\r|\n/)
      this.batchForm.phone = [...new Set(arr)]
      // console.log('handleBatchAdd', this.batchForm)
      // return;
      if (this.batchTelTypeRadio == '0') {
        if (null == this.batchForm.disableDuration) {
          await  this.$notify({
          title: '警告',
          message: '请选择禁用时长',
          type: 'warning'
        });
          return;
        }
        let params = {
          phone: this.batchForm.phone,
          remark: this.batchForm.remark,
          reason: this.batchForm.reason,
          disableDuration: this.batchForm.disableDuration,
          amountId: this.batchForm.amountId,
        };
        voiceType
            .additionBatch(params)
            .then((res) => {
              if (res.code !== 200) {
                this.$message.error(res.message)
                return false
              } else {
                this.batchForm.amountId = '0';
                this.batchForm.reason = null
                this.batchForm.phoneString = '';
                this.batchForm.phone = [];
                this.batchForm.remark = ''
                this.batchForm.disableDuration = null;
                this.$message.success('主叫号码已批量加入黑名单！')
                this.DialogBatchaddVisible = false
                this.getDataList(true)
              }
            })
      } else {
        voiceType
            .additionBlacklistBatch({phone: this.batchForm.phone, remark: this.batchForm.remark})
            .then((res) => {
              if (res.code !== 200) {
                this.$message.error(res.message)
                return false
              } else {
                this.batchForm.amountId = '0';
                this.batchForm.reason = null
                this.batchForm.phoneString = '';
                this.batchForm.phone = [];
                this.batchForm.remark = ''
                this.$message.success('被叫号码已批量加入黑名单！')
                this.DialogBatchaddVisible = false
                this.getDataList(true)
              }
            })
      }
    },
     //批量删除黑名单添加
    async handleBatchDel() {
      let arr = this.delForm.phoneString.split(/\r\n|\r|\n/)
      this.delForm.phone = [...new Set(arr)]
       this.$confirm('将批量解除该批号码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(()=>{
                if (this.telType==0) {
           voiceType
            .DeletePlus({phone: this.delForm.phone, amountId: this.delForm.amountId})
            .then((res) => {
              if (res.code !== 200) {
                this.$message.error(res.message)
                return false
              } else {
                this.delForm.amountId = '0';
                this.delForm.phoneString = '';
                this.delForm.phone = [];
                this.$message.success('主叫号码已批量解除！')
                this.DialogBatchaddVisible1 = false
                this.getDataList(true)
              }
            })
      }else if(this.telType==1){
            voiceType
            .DeleteNumberPlus({phone:this.delForm.phone})
            .then(res=>{
                  if (res.code !== 200) {
                this.$message.error(res.message)
                return false
              } else {
                this.delForm.phoneString = '';
                this.delForm.phone = [];
                this.$message.success('被叫号码已批量解除！')
                this.DialogBatchaddVisible1 = false
                this.getDataList(true)
              }
            })
             }else{
               voiceType
            .DeleteWritePlus({nums:this.delForm.phone,status:'0'})
            .then(res=>{
                  if (res.code !== 200) {
                this.$message.error(res.message)
                return false
              } else {
                this.delForm.phoneString = '';
                this.delForm.phone = [];
                this.$message.success('小号号码已批量解除！')
                this.DialogBatchaddVisible1 = false
                this.getDataList(true)
              }
            })
            }
        })
    },
    // 删除
    deleteOne(index) {
      this.blackTelADtoLists.splice(index, 1)
    },
    // 添加一条
    addprivacy() {
      let newJson = {
        hitType: '打人',
        telA: '',
        disableDuration: null,
        remark: '',
        amountId: '0',
      }
      this.blackTelADtoLists.push(newJson)
    },
    // 查询
    onSubmit() {
      this.theQueryDialog = false
      this.getDataList(true)
    },
    telTypeChange(val) {
      this.searchFrom= {
        page: 1,
        pageSize: 10,
        telA: '',
        number: '',
      },
      this.valueTime=[]
      this.getDataList(true)
    },
    // 重置
    reset(kind) {
      this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
      }
      this.valueTime = []
      if (kind == 1) {
        this.getDataList(true)
      }
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type
      if (this.valueTime != null) {
        this.searchFrom.createTimeBegin = this.valueTime[0]
        this.searchFrom.createTimeEnd = this.valueTime[1]
      } else {
        this.searchFrom.createTimeBegin = ''
        this.searchFrom.createTimeEnd = ''
      }
      if (this.telType == '0') {
        blackNumber
            .AblackNumberlist({
              ...this.searchFrom,
            })
            .then((res) => {
              this.tableData = res.data.records
              this.total = res.data.total
              this.loading = false
            })
      } else if(this.telType == '1') {
        blackNumber
            .blackNumberList({
              ...this.searchFrom,
            })
            .then((res) => {
              this.tableData = res.data.records
              this.total = res.data.total
              this.loading = false
            })
      }else{
        blackNumber.getListDisablelist({
              ...this.searchFrom,
            }).then(res=>{
              this.tableData = res.data.records
              this.total = res.data.total
              this.loading = false
        })
      }
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.searchFrom.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val
      this.currentPage = val
      this.getDataList(true)
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val
      // this.multipleSelection = []
      // val.forEach(item => {
      //     this.multipleSelection.push(item.id)
      // });
    },
    // 查看详情
    viewDetails(row) {
      if (row.checkId == null) {
        this.$notify({
          title: '消息',
          message: '当前消息并无详情！',
          type: 'warning',
        })
      } else {
        blackNumber
            .getListBlack({
              id: row.checkId,
            })
            .then((res) => {
              this.disableDetails = res.data
              this.disableDetails.disableTime = row.createTime
              this.detailsDialog = true
            })
      }
    },
    // 删除确认
    confirmEvent(row) {
      let idList = []
      this.multipleSelection.forEach((item) => {
        idList.push(item.id)
      })
      if (this.telType == '0') {
        this.$confirm('将解禁该号码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          blackNumber
              .AblackNumberdelete({
                ids: this.multipleSelection.length == 0 ? [row.id] : idList,
              })
              .then((res) => {
                this.$notify({
                  title: '成功',
                  message: '号码解禁成功！',
                  type: 'success',
                })
                this.getDataList(true)
              })
        })
      }else if(this.telType == '1') {
        this.$confirm(
            this.multipleSelection.length == 0
                ? '将解禁该号码, 是否继续?'
                : '将解禁选中号码, 是否继续?',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              blackNumber
                  .blackNumberDelete({
                    ids: this.multipleSelection.length == 0 ? [row.id] : idList,
                  })
                  .then((res) => {
                    this.$notify({
                      title: '成功',
                      message: '号码解禁成功！',
                      type: 'success',
                    })
                    this.getDataList(true)
                  })
            })
            .catch(() => {
              // this.$notify.info({
              //     title: '通知',
              //     message: '已取消删除!'
              // });
            })
      }else{
            this.$confirm(
            this.multipleSelection.length == 0
                ? '将解禁该号码, 是否继续?'
                : '将解禁选中号码, 是否继续?',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(()=>{
                     blackNumber.deleteDisablelist({
              id:row.id
            }).then(res=>{
              if (res.code==200) {
                this.$notify({
                  title: '成功',
                  message: '号码解禁成功！',
                  type: 'success',
                })
                this.getDataList(true)
              }else{
                 this.$notify.info({
                  title: '通知',
                  message: res.msg||res.message
              });
              }
            })
            })
          
      }
    },
    unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a) && res.set(a, 1))
    },
    // 新增确认提交
    async submitForm() {
      const reg =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/
      const valid = this.blackTelADtoLists.some((item) => {
        if (!reg.test(item.telA) || item.telA == '') {
          return item.telA
        }
      })
      if (valid) {
        return this.$notify({
          title: '失败',
          message: '手机号码格式有误',
          type: 'error',
        })
      }
      if (this.telTypeRadio == '0') {
        let needDisableDuration = this.blackTelADtoLists.find(item => null == item.disableDuration);
        if (needDisableDuration) {
          await this.$notify({
          title: '警告',
          message: '请选择禁用时长',
          type: 'warning'
        });
          return;
        }
        const blackTelADtoList = this.blackTelADtoLists.map(item => {
          let res = {
            telA: item.telA,
            remark: item.remark,
            reason: item.reason,
            disableDuration: item.disableDuration,
            amountId: item.amountId || '0',
          };
          return res;
        })
        voiceType.batchAddition({blackTelADtoList}).then((res) => {
          this.$notify({
            title: '成功',
            message: '主叫号码已加入黑名单！',
            type: 'success',
          })
          this.getDataList(true)
          this.centerDialogVisible = false
        })
      } else {
        const blackNumberDtoList = []
        this.blackTelADtoLists.map((item) => {
          blackNumberDtoList.push({
            number: item.telA,
            remark: item.remark,
          })
        })
        voiceType.batchAdditionBlacklist({blackNumberDtoList}).then((res) => {
          console.log(res)
          this.$notify({
            title: '成功',
            message: '被叫号码已加入黑名单！',
            type: 'success',
          })
          this.getDataList(true)
          this.centerDialogVisible = false
        })
      }
    },
    resetForm() {
      this.blackTelADtoLists = [
        {
          telA: '',
          remark: '',
          amountId: '0',
        },
      ]
      this.centerDialogVisible = false
    },
    closeDialog() {
      this.blackTelADtoLists = [
        {
          telA: '',
          remark: '',
          amountId: '0',
        },
      ]
      this.centerDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.privacy-most {
  height: calc(80vh - 400px);
  overflow-y: overlay;

  .pItem-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;

    .input-price-width {
      width: 150px;
    }
  }
}

.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 18px;

  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }

  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}

.screening {
  margin-bottom: 0.9375rem;
}

.el-row {
  margin-bottom: 0.9375rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.editor {
  color: #0170ed;
  background: #e3f0ff;
}

// 添加黑名单弹窗
.addBlack {
  height: 100%;
  width: 90%;

  .header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.center-body {
  margin: 0 1.875rem;
}

.deletetable {
  color: #ff845b;
  background: #ffe4db;
}

.deletetable-other {
  margin-right: 1.25rem;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.screening-out {
  margin: 0 0.9375rem;
}

.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}

.pager {
  display: flex;
  justify-content: flex-end;
}

.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}

.eltable {
  overflow-y: overlay;
}
</style>